require('dotenv').config('.env');

export const VIDEOS_CONFIG = {
    programs: {
        path: 'program?categories=37',
    },
    kcc: {
        path: 'kcc',
    },
};
export const IS_LOCAL = /localhost/.test(window.location.href);
export const LOGGED_IN = document.body.classList.contains('logged-in');

const API_URL = process.env.TV_API_URL || `https://kapi.kadist.org/ktv_api/`;

export const fetchWp = path => {
    return fetch(`https://kadist.org/wp-json/wp/v2/${path}`)
    .then(res => res.json());
};

export const fetchTrending = what => {
    return fetch(`${API_URL}suggested_videos?count=50`)
    .then(res => res.json());
};
export const fetchAll = () => {
    return fetch(`${API_URL}all_videos`)
    .then(res => res.json());
};

export const pinnedList = videoId => {
    return fetch(`${API_URL}pin`)
    .then(res => res.json());
};

export const statsList = () => {
    return fetch(`${API_URL}stats`)
    .then(res => res.json());
};


export const unpinVideo = id => {
    fetch(`${API_URL}pin/${id}`, {
        method: 'delete', // *get, post, put, delete, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    });
};
export const pinVideo = id => {
    fetch(`${API_URL}pin/${id}`, {
        method: 'post', // *get, post, put, delete, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    });
};

export const videoWatched = id => {
    fetch(`${API_URL}watched/${id}`, {
        method: 'post', // *get, post, put, delete, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    });
};

export function isTouchDevice() {
    return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
}

export function isMobileWidth() {
    return window.innerWidth <= 1000;
}

// https://stackoverflow.com/questions/16801687/javascript-random-ordering-with-seed
export function shuffle(array, seed) {                // <-- ADDED ARGUMENT
    var m = array.length; var t; var i;

    // While there remain elements to shuffle…
    while (m) {
    // Pick a remaining element…
        i = Math.floor(random(seed) * m--);        // <-- MODIFIED LINE

        // And swap it with the current element.
        t = array[m];
        array[m] = array[i];
        array[i] = t;
        ++seed; // <-- ADDED LINE
    }

    return array;
}

export function random(seed) {
    var x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
}

export const setStorage = (key, val) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(val));
    } catch (err) {

    }
};

export const getStorage = key => {
    try {
        const val = window.localStorage.getItem(key);
        if (val) {
            return JSON.parse(val);
        }
    } catch (err) {
    }
};
