import React, { useRef, useState, memo, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { YoutubeEmbed } from '../common';
import classNames from 'classnames';
import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player';
import useVisibilitySensor from '@rooks/use-visibility-sensor';
import { useFetchVideos } from '../common/redux/hooks';

const Video = memo(({ url, playing, muted = true, poster, embedId, controls, vimeoId, thumb, isPaging=true }) => {
    const player = useRef();
    const rootNode = useRef(null);
    const [t, setT] = useState(0);
    const [start, setStart] = useState();
    const howLong = 10; // seconds
    const { isVisible, visibilityRect } = useVisibilitySensor(rootNode, {
        intervalCheck: 200,
        scrollCheck: true,
        resizeCheck: true,
        partialVisibility: true,
    });
    const { isPlaying } = useFetchVideos();

    useEffect(() => {
        if (!vimeoId) {return;}
        if (!player.current) {return;}
        if (playing) {
            player.current.play();
        } else {
            player.current.pause();
        }
        console.log(JSON.stringify({ playing, muted, vimeoId, url }));
    }, [playing, muted, vimeoId]);
    if (vimeoId) {
        return <Vimeo onReady={plyr => {
            player.current = plyr;
            if (plyr) {
                player.current.play();
            }
        }} className='video-responsive' controls={controls} video={vimeoId} autoplay={true} muted={muted} />;
    }
    if (embedId) {
        return <YoutubeEmbed autoplay={playing} embedId={embedId} />;
    }
    let show = <ReactPlayer
        ref={player}
        className='react-player'
        playing={playing}
        muted={muted}
        controls={controls}
        config={{
            attributes: {
                poster: thumb,
            },
        }}
        url={url}
        width='100%'
        height='100%'
        autoPlay={playing}
        playsinline={true}

    />;
    const style = {};
    if (!isVisible && !playing) {
        show = null;
    // style.backgroundImage = `url(${poster})`
    }
    return (
        <div ref={rootNode} style={style} className={classNames('home-video', {
            visible: isVisible,
        })}>
            {show}
        </div>
    );
});

Video.propTypes = {};
Video.defaultProps = {};

export default Video;
