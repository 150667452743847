import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    COMMON_IS_ADMIN
} from './constants';

export function isAdmin() {
    return {
        type: COMMON_IS_ADMIN,
    };
}

export function useIsAdmin() {
    const dispatch = useDispatch();
    const boundAction = useCallback((...params) => dispatch(isAdmin(...params)), [dispatch]);
    const st8Obj = useSelector(
    state => {
        const st8 = {
            isAdmin: state.common.isAdmin,
        };
        return st8;
    },
    shallowEqual
    );
    return st8Obj;
}

export function reducer(state, action) {
    switch (action.type) {
        case COMMON_IS_ADMIN:
            return {
                ...state,
            };

        default:
            return state;
    }
}
