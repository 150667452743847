import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_FETCH_REGIONS_BEGIN, COMMON_FETCH_REGIONS_SUCCESS} from './constants';

const API_URL = process.env.TV_API_URL || `https://localhost:7357/ktv_api/`;
const WP_API = process.env.TV_WP_URL || `https://localhost/wp-json/wp/v2/`;

export function fetchRegions (args = {}) {
    return (dispatch) => {
        dispatch({
            type: COMMON_FETCH_REGIONS_BEGIN,
        });
        const generated = {
            type: COMMON_FETCH_REGIONS_SUCCESS,
            data: {
                regions: [
                    {label: 'All', value: 999 },
                    {label:'Latin America', value: 133},
                    {label:'Europe', value: 41},
                    {label:'North America', value: 131},
                    {label:'Oceania', value: 43},
                    {label:'Americas', value: 135},
                    {label:'Middle East & Africa', value: 47},
                    {label:'Asia', value: 45},
                ],
            },
        };

        dispatch(generated);
        return generated;
    };
}

export function useFetchRegions () {
    const dispatch = useDispatch();
    const { regions } = useSelector(
    state => ({
        regions: state.common.regions,
    })
    );

    const boundAction = useCallback((...args) => {
        return dispatch(fetchRegions(...args));
    }, [dispatch]);

    return {
        regions,
        fetchRegions: boundAction,
    };
}

export function reducer (state, action) {
    switch (action.type) {
        case COMMON_FETCH_REGIONS_BEGIN:
            return {
                ...state,
                fetchRegionsPending: true,
                fetchRegionsError: null,
            };

        case COMMON_FETCH_REGIONS_SUCCESS:
            return {
                ...state,
                fetchRegionsPending: false,
                fetchRegionsError: null,
                regions: action.data.regions,
            };

        default:
            return state;
    }
}

