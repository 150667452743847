import React, { useEffect } from 'react';
import { useFetchVideos } from '../common/redux/hooks';
import { Carousel } from 'react-responsive-carousel';
import { VideoHero } from '.';
import { useParams } from 'react-router-dom';
// import PropTypes from 'prop-types';

export default function PlainCarousel () {
    const { isPaging, fetchVideos, trending, external_videos, kvl_videos, interview_videos, fetchVideosPending } = useFetchVideos();
    // init
    useEffect(() => {
        fetchVideos();
    }, []);
    const videos = [...external_videos, ...kvl_videos, ...interview_videos].sort( () => 0.5 - Math.random());
    const video_id = useParams().id || false;
    const first = videos.find(v => v.permalink === video_id);
    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;
    if (first) {

        videos.unshift(first);
    }
    return (
        <div className='home-plain-carousel'>
            <header>Kadist.Tv</header>
            <Carousel
                showStatus={false}
                swipeable
                showThumbs={false}
                renderItem={customRenderItem}
                showIndicators={false}
                autoPlay={isPaging}
                interval={19000}
            >
                {videos.map((v, i) =>
                    <VideoHero
                        key={v.id}
                        video={v}
                        playing
                        muted={false}
                    />
                )}
            </Carousel>
        </div>
    );
}

PlainCarousel.propTypes = {};
PlainCarousel.defaultProps = {};
