import React, { useRef } from 'react';
import classNames from 'classnames';
import { useSetFavs } from '../common/redux/hooks';
import {
    Video
} from './';
import useVisibilitySensor from '@rooks/use-visibility-sensor';
import { isTouchDevice } from '../../common/utils';
import { Link } from 'react-router-dom';
import { object } from 'bfj/src/events';
// import PropTypes from 'prop-types';

export default function SectionVideoWrap({ onMouseEnter, onMouseLeave, v, playing, onFav, onClick }) {
    const video_title = v.work_details && v.work_details.newVideoTitle ? v.work_details.newVideoTitle : v.extracted_video_title;
    const { setFavs, favs } = useSetFavs();
    const nodeRef = useRef();
    const fav = favs.includes(v.id);
    const [firstTouch, setFirstTouch] = React.useState(false);
    const { isVisible, visibilityRect } = useVisibilitySensor(nodeRef, {
        intervalCheck: 200,
        scrollCheck: true,
        resizeCheck: true,
        partialVisibility: true,
    });
    let style = {};
    let loading = true;
    if (!playing && isVisible) {
        style.backgroundImage = `url(${v.image_url})`;
        loading = false;
    }
    return <div
        ref={nodeRef}
        onMouseEnter={() => onMouseEnter(v.id)}
        onMouseLeave={() =>  onMouseLeave(v.id)}
        className={classNames('video-wrap', { playing, fav, loading, hovered: firstTouch })}
    >
        <Link className='video-a'
            style={style}
            to={`/kadist-tv/?id=${v.id}`}
            onClick={() => {
                if (isTouchDevice() && !firstTouch) {
                    setFirstTouch(true);
                    return;
                }
                if (onClick) {
                    onClick(v);
                }
                setFirstTouch(false);
            }}
        />
        <div className='info'>
            <div className='details'>
                <h3>{video_title}</h3>
                <p>
                    {v.extracted_video_artist}{v.extracted_year && ', ' + v.extracted_year}
                </p>
            </div>
            <a className='fav' onClick={onFav} />
            <a className='caret' onClick={() => setFirstTouch(false)} />
        </div>
    </div>;
}

SectionVideoWrap.propTypes = {};
SectionVideoWrap.defaultProps = {};
