import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_FETCH_DURATIONS_BEGIN, COMMON_FETCH_DURATIONS_SUCCESS} from './constants';

const API_URL = process.env.TV_API_URL || `https://localhost:7357/ktv_api/`;
const WP_API = process.env.TV_WP_URL || `https://localhost/wp-json/wp/v2/`;

export function fetchDurations (args = {}) {
    console.log('fetchDuration :D');
    return (dispatch) => {
        dispatch({
            type: COMMON_FETCH_DURATIONS_BEGIN,
        });
        console.log('fetchDuration CB :D');
        const generated = {
            type: COMMON_FETCH_DURATIONS_SUCCESS,
            data: {
                durations: [
                    {name:'< 60 sec', id: 123},
                    {name:'1 - 5 min', id: 456},
                    {name:'5 - 20 min', id: 789},
                    {name:'20+ min', id: 342},
                ],
            },
        };

        dispatch(generated);
        return generated;
    };
}

export function useFetchDurations () {
    const dispatch = useDispatch();
    console.log('useFetchDuration :D');
    const { durations } = useSelector(
    state => ({
        durations: state.common.durations,
    })
    );

    const boundAction = useCallback((...args) => {
        return dispatch(fetchDurations(...args));
    }, [dispatch]);

    return {
        durations,
        fetchDurations: boundAction,
    };
}

export function reducer (state, action) {
    switch (action.type) {
        case COMMON_FETCH_DURATIONS_BEGIN:
            return {
                ...state,
                fetchDurationsPending: true,
                fetchDurationsError: null,
            };

        case COMMON_FETCH_DURATIONS_SUCCESS:
            return {
                ...state,
                fetchDurationsPending: false,
                fetchDurationsError: null,
                durations: action.data.durations,
            };

        default:
            return state;
    }
}

