import React from 'react';
// import PropTypes from 'prop-types';

export default function SafeSpan ({ content }) {
    return (
        <span className='common-safe-span' dangerouslySetInnerHTML={{ __html: content }} />
    );
}

SafeSpan.propTypes = {};
SafeSpan.defaultProps = {};
