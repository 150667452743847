import React from 'react';
import PropTypes from 'prop-types';

const YoutubeEmbed = ({ embedId, autoplay }) => (
    embedId &&
  <div className='video-responsive'>
      <iframe
          id={embedId}
          width='853'
          height='480'
          src={`https://www.youtube.com/embed/${embedId}?controls=0&modestbranding=1&enablejsapi=1${autoplay ? '&autoplay=1' : ''}`}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          title='Embedded youtube'
      />
  </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
