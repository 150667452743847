export const COMMON_FETCH_VIDEOS_BEGIN = 'COMMON_FETCH_VIDEOS_BEGIN';
export const COMMON_FETCH_VIDEOS_SUCCESS = 'COMMON_FETCH_VIDEOS_SUCCESS';
export const COMMON_FETCH_VIDEOS_FAILURE = 'COMMON_FETCH_VIDEOS_FAILURE';
export const COMMON_FETCH_VIDEOS_DISMISS_ERROR = 'COMMON_FETCH_VIDEOS_DISMISS_ERROR';
export const COMMON_IS_ADMIN = 'COMMON_IS_ADMIN';
export const COMMON_SET_FAVS_BEGIN = 'COMMON_SET_FAVS_BEGIN';
export const COMMON_SET_FAVS_SUCCESS = 'COMMON_SET_FAVS_SUCCESS';
export const COMMON_SET_FAVS_FAILURE = 'COMMON_SET_FAVS_FAILURE';
export const COMMON_SET_FAVS_DISMISS_ERROR = 'COMMON_SET_FAVS_DISMISS_ERROR';
export const COMMON_FETCH_REGIONS_BEGIN = 'COMMON_FETCH_REGIONS_BEGIN';
export const COMMON_FETCH_REGIONS_SUCCESS = 'COMMON_FETCH_REGIONS_SUCCESS';
export const COMMON_FETCH_DURATIONS_BEGIN = 'COMMON_FETCH_DURATIONS_BEGIN';
export const COMMON_FETCH_DURATIONS_SUCCESS = 'COMMON_FETCH_DURATIONS_SUCCESS';
