import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useFetchRegions, useFetchVideos, useSetFavs, useFetchDurations } from '../common/redux/hooks';
import Select from 'react-select';
import { Video, VideoHero, Section } from './';
import { Carousel } from 'react-responsive-carousel';
import {
    not,
    propEq,
    findIndex,
    pluck,
    pipe,
    flatten,
    uniq,
    sort,
    identity,
    anyPass,
    propSatisfies,
    test,
    filter, set
} from 'ramda';
import classNames from 'classnames';
import { IS_LOCAL, LOGGED_IN, videoWatched, getStorage, setStorage } from '../../common/utils';

export default function WelcomePage() {
    const {
        fetchVideos,
        trending,
        external_videos,
        kvl_videos,
        kview_videos,
        interview_videos,
        fetchVideosPending,
        custom_channels,
    } = useFetchVideos();
    const [videos, setVideos] = useState([]);
    const [videoI, setVideoI] = useState(0);
    const { regions, fetchRegions } = useFetchRegions();
    const { durations, fetchDurations } = useFetchDurations();
    const { setFavs, favs } = useSetFavs();
    const [isPaging, setIsPaging] = useState(false);
    const [isFaqOpen, setIsFaqOpen] = useState(false);

    const format_channel_date = (date) => {
        let formatted = '';
        let past = false;
        if (date && date.length === 8) {
            const year = date.substring(0, 4);
            const month = date.substring(4, 6);
            const day = date.substring(6, 8);
            let date_obj = new Date(year, month - 1, day);
            formatted = date_obj.toLocaleDateString('en-US', { month: 'long', day: 'numeric' });
            if (date_obj < new Date()) {
                past = true;
            }
        }

        return { formatted, past };
    };
    const loadParamVideo = () => {
        const video_id = new URLSearchParams(window.location.search).get('id');
        const videoArr = [...external_videos, ...kvl_videos, ...interview_videos, ...kview_videos].filter(v => v.id === video_id);
        if (videoArr.length > 0) {
            setVideos([...videos, ...videoArr]);
            setVideoI(findIndex(propEq('id', videoArr[0].id), videoArr));
            setIsPaging(false);
        } else {
            setVideos([...external_videos, ...kvl_videos, ...interview_videos, ...kview_videos]);
        }
    };
    // init
    useEffect(() => {
        fetchVideos();
        fetchRegions();
        fetchDurations();
        setFavs();
    }, []);
    useEffect(() => {
        const param = new URLSearchParams(window.location.search).get('id');
        if (!param && trending && trending.length < 1) {
            setVideos(kvl_videos);
        } else if(!param) {
            setVideos(trending);
        } else {
            loadParamVideo();
        }
    }, [trending]);
    useEffect(() => {
        loadParamVideo();
    }, []);
    const inputRef = useRef();
    const timeoutRef = useRef();
    const hoveredRef = useRef();
    const youtubeEmbed = useRef();
    const [beenHereBefore, setBeenHereBefore] = useState(getStorage('beenHereBefore'));
    const [option, setOption] = useState('all');
    const [region, setRegion] = useState(false);
    const [duration, setDuration] = useState(false);
    const [openChannels, setOpenChannels] = useState([]);
    const [infoOpen, setInfoOpen] = useState(false);
    const [login, setLogin] = useState(false);
    const [modalVideo, setModalVideo] = useState(false);
    const [playing, setPlaying] = useState(true);
    const [search, setSearch] = useState('');
    const [searchFocused, setSearchFocused] = useState(false);
    const [muted, setMuted] = useState(true);
    const [hovered, _setHovered] = useState(false);
    const [navOpen, setNavOpen] = useState(false);
    const copyVideosFromFavs = () => {
        const videos = [...external_videos, ...kvl_videos, ...interview_videos, ...kview_videos];
        return videos.filter(v=>favs.includes(v.id));
    };
    const setHovered = val => {
        hoveredRef.current = val;
        _setHovered(val);
    };
    // useEffect(() => {
    //   if (search === '') {
    //     inputRef.current.focus()
    //   }
    // }, [search])
    const FILTERS = {
        all: {
            func: identity,
        },
        newest: {
            func: sort((a, b) => b.extracted_year - a.extracted_year),
        },
        popular: {
            func: sort((a, b) => b.popularity - a.popularity),
        },
        favs: {
            label: 'My FAVORITES',
            func: filter(v => favs.includes(v.id)),
        },
        region: {
            component: () => {
                return (
                    <Select
                        key="region-select"
                        className="select option"
                        classNamePrefix="select"
                        options={ regions }
                        value={option}
                        onChange={e=>{
                            setOption('region')
                            setRegion(e)
                        }}
                        placeholder={region.label || 'REGION'}
                    />
                );
            },
            func: filter((v) => {
                if (!region || region.label === 'All') {
                    return true;
                }
                return regions.find(r => r.label === region.label).label === v.region;
            }),
        },
        duration: {
            component: () => {
                return (
                    <Select
                        key="duration-select"
                        className="select option"
                        classNamePrefix="select"
                        // options={durations
                        // .map(duration => ({ value: duration.id, label: duration.name }))}
                        options={[
                            { value: NaN, label: 'All' },
                            { value: 0, label: '< 60 sec' },
                            { value: 60, label: '1 - 5 min' },
                            { value: 300, label: '5 - 20 min' },
                            { value: 1200, label: '20+ min' },
                        ]}
                        value={option}
                        onChange={e => {
                            setOption('duration');
                            setDuration(e);
                            setNavOpen(false);
                        }}
                        placeholder={duration.label || 'DURATION'}
                    />

                );
            },
            func: filter((v) => {
                if (!duration || isNaN(duration.value)) {
                    return true;
                }
                if (!v.mp4_length) {
                    return false;
                }
                const dur = parseInt(v.mp4_length, 10);
                if (duration.value === 0) {
                    return dur < 60;
                } else if (duration.value === 60) {
                    return dur >= 60 && dur < 300;
                } else if (duration.value === 300) {
                    return dur >= 300 && dur < 1200;
                } else {
                    return dur >= 1200;
                }
            }),
        },
    };

    const loggedIn = LOGGED_IN;
    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;
    const onSectionClick = videoArr => {
        return video => {
            videoWatched(video.id);
            setVideos(videoArr);
            setVideoI(findIndex(propEq('id', video.id), videoArr));
            setMuted(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setIsPaging(false);
        };
    };
    const doVideoFilter = arr => {
        if (search === false || search === '') {
            return FILTERS[option].func(arr).filter(v => {
                if (option === 'newest' || option === 'popular') {
                    return v.extracted_year;
                }
                return true;
            });
        }
        const searchField = propSatisfies(test(new RegExp(search, 'i')));
        const fields = [
            'extracted_year',
            'description',
            'title',
            'abbreviated_description',
            'extracted_video_artist',
            'extracted_video_title',
        ];
        const hasTag = video =>
            (video.tags || []).map(s => s.toLowerCase()).includes(search.toLowerCase());
        return FILTERS[option].func(arr).filter(anyPass([...fields.map(f => searchField(f)), hasTag]));
    };
    const labels = Object.assign(
        {
            kvl: 'Collection',
            trend: 'Trending',
            external: 'Kadist Relevant',
            interview: 'Artist Interviews',
            kviews: '60 seconds with...',
            favorites: 'My Favorites',
        },
        window.tvLabels || {}
    );
    if (fetchVideosPending) {
        return <div className="home-welcome-page loading">Loading...</div>;
    }
    const hoveredFn = () => {
        console.log('hovered')
        setHovered(true);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setHovered(false), 3000);
        setIsPaging(false);
    };

    const onMouseEnter = ()=>{
        console.log('enter')
        setHovered(true);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setHovered(false), 3000);
        setIsPaging(false);
    }

    const onMouseLeave = ()=>{
        console.log('leave')
        setHovered(false);
    };
    const loginStr = login && typeof login === 'string' ? login : 'https://kadist.org/kadist-tv';

    const optionsWrap = (
        <span className="options-wrap">
            {Object.keys(FILTERS).map((o, i) =>
                FILTERS[o].component ? (
                    FILTERS[o].component()
                ) : (
                    <a
                        key={i}
                        className={classNames(`option-${o}`, { selected: option === o }, 'option')}
                        onClick={() => {
                            setOption(o);
                            setRegion(false);
                            setNavOpen(false);
                        }}
                    >
                        {FILTERS[o].label || o}
                    </a>
                )
            )}
        </span>
    );
    let altI = 0;
    const getAlt = () => {
        altI += 1;
        return altI % 2 === 0;
    };
    const togglePauseVideo = () => {
        setPlaying(!playing);
    }
    const channelSection = channel => {
        const filteredVideos = doVideoFilter(channel.videos);
        const channelEnd = format_channel_date(channel.end);
        if (filteredVideos.length === 0) {return null;}
        if (channelEnd.past) {return null;}
        return (
            <div
                className={classNames('section-wrap custom-channel', {
                    info: !!channel.description,
                    open: openChannels.includes(channel.title),
                })}
                key={channel.title}
            >
                <header>
                    <h3>
                        {channel.title}{' '}
                        {channel.description && channel.title && (
                            <a
                                className="learn"
                                onClick={() => {
                                    if (openChannels.includes(channel.title)) {
                                        setOpenChannels(openChannels.filter(c => c !== channel.title));
                                    } else {
                                        setOpenChannels([...openChannels, channel.title]);
                                    }
                                }}
                            />
                        )}
                    </h3>
                    <div className="end-date">{channel.endCTA ? channel.endCTA : ''} { channelEnd.formatted }</div>
                </header>
                {channel.description && (
                    <div className={classNames('kvl-info', {})}>
                        <div dangerouslySetInnerHTML={{ __html: channel.description }} className="content" />
                        { channel.channel_end_date ? <p>{ channel.channel_end_date }</p>: '' }
                    </div>
                )}
                <Section
                    alt={getAlt()}
                    videos={filteredVideos}
                    onClick={onSectionClick(channel.videos)}
                    setLogin={setLogin}
                />
            </div>
        );
    };

    return (
        <div
            className={classNames('home-welcome-page', {
                hovered,
                'logged-in': loggedIn,
                'logged-out': !loggedIn,
            })}
            onClick={() => {
                // if (LOGGED_IN) return
                // setLogin(true)
            }}
        >
            <div className="controls">
                {/* <a className={classNames('play', {
              playing
            })} onClick={() => setPlaying(!playing)} /> */}
                <a
                    className={classNames('mute', {
                        muted,
                    })}
                    onClick={() => setMuted(not)}
                />
                {/* <div className={classNames('search', { open: search !== false })}>
              <a
                className='search'
                onClick={evt => {
                  if (search === false) {
                    setSearch('')
                  } else {
                    setSearch(false)
                  }
                }}
              />
            </div> */}
            </div>
            <div
                onMouseMove={hoveredFn}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onTouchEnd={hoveredFn}
                onClick={togglePauseVideo}
                className={classNames('carousel-container', {isPaging})}
            >
                {videos.length > 0 && (
                    <Carousel
                        showStatus={false}
                        swipeable
                        infiniteLoop
                        stopOnHover={true}
                        showThumbs={false}
                        renderItem={customRenderItem}
                        onChange={i => {
                            const currentVideo = videos[videoI];
                            const pauseVideo = id => {
                                if (youtubeEmbed.current) {
                                    youtubeEmbed.current.pauseVideo();
                                } else {
                                    youtubeEmbed.current = new window.YT.Player(id, {
                                        events: {
                                            onReady: () => {
                                                youtubeEmbed.current.pauseVideo();
                                            },
                                        },
                                    });
                                }
                            };
                            if (currentVideo.embedId && i !== videoI && window.YT) {
                                pauseVideo(currentVideo.embedId);
                            }
                            const newVideo = videos[i];
                            if (newVideo.embedId && i !== videoI && youtubeEmbed.current) {
                                youtubeEmbed.current.playVideo();
                            }
                            setVideoI(i);
                        }}
                        selectedItem={videoI}
                        showIndicators={false}
                        autoPlay={isPaging}
                        interval={19000}
                        renderArrowNext={(clickHandler, hasNext, label) => {
                            const nextI = videoI === videos.length - 1 ? 0 : videoI + 1;
                            return hasNext && <a onClick={clickHandler} className="next arrow" />;
                        }}
                        renderArrowPrev={(clickHandler, hasPrev, label) => {
                            const prevI = videoI === 0 ? videos.length - 1 : videoI - 1;
                            return hasPrev && <a onClick={clickHandler} className="prev arrow" />;
                        }}

                    >
                        {videos.map((v, i) => (
                            <VideoHero
                                key={i}
                                video={v}
                                playing={!modalVideo && i === videoI && playing}
                                muted={muted}
                                playsinline
                                onClick={() => {
                                    togglePauseVideo();
                                    if (loggedIn) {
                                        //setModalVideo(v);
                                        setIsPaging(false);
                                    } else {
                                        setLogin(true);
                                    }
                                }}
                            />
                        ))}
                    </Carousel>
                )}
            </div>
            <nav className={classNames('utility', { open: navOpen })}>
                <div className="search">
                    <input
                        placeholder={searchFocused ? 'Artists, Titles, Themes, Year...' : 'SEARCH TV:'}
                        ref={inputRef}
                        type="text"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        onFocus={() => setSearchFocused(true)}
                        onBlur={() => setSearchFocused(false)}
                        onKeyDown={evt => {
                            if (evt.keyCode === 27) {
                                setSearch(false);
                            }
                        }}
                    />
                </div>
                <div
                    className={classNames('options', option, { open: navOpen, 'not-all': option !== 'all' })}
                >
                    <span className="sort-by">Sort By: </span>
                    <a onClick={() => setNavOpen(not)} className="o">
                        {FILTERS[option].label || option}
                    </a>
                    {optionsWrap}
                </div>
                {optionsWrap}
                {!loggedIn && (<a className="login" onClick={() => setLogin(true)}>
                    Log In
                </a>)}
                <a className="faq" onClick={() => setIsFaqOpen(!isFaqOpen)}>?</a>
            </nav>
            <div className="sections">


                {custom_channels.filter(c => !c.bottom).map(channelSection)}
                {doVideoFilter(kvl_videos).length > 0 && (<div
                        className={classNames('section-wrap', {
                            info: window.kvlInfo, open: infoOpen, 'logged-in': loggedIn,
                        })}
                    >
                        <header>
                            <h3>
                                {labels.kvl} <a className="learn" onClick={() => setInfoOpen(not)} />
                            </h3>
                        </header>
                        {window.kvlInfo && (
                            <div className="kvl-info">
                                <div dangerouslySetInnerHTML={{ __html: window.kvlInfo }} className="content" />
                                <div className="more-info">
                                    <p>
                                        Once logged-in, visit the KVL Catalog to select a video, or use the search bar.
                                    </p>
                                    <p>
                                        For more infomartion, visit the <a href="https://kadist.org/kvl-faq/">FAQ</a>
                                    </p>
                                    <a className="login" onClick={() => setLogin(true)}>
                                        Member Log In
                                    </a>
                                    <a className="btn" href="https://kadist.org/register/">
                                        Register
                                    </a>
                                </div>
                            </div>
                        )}
                        <Section
                            alt={getAlt()}
                            videos={doVideoFilter(kvl_videos)}
                            onClick={onSectionClick(kvl_videos)}
                            setLogin={setLogin}
                        />
                    </div>
                )}
                {doVideoFilter(interview_videos).length > 0 && (
                    <div className="section-wrap interview">
                        <header>
                            <h3>{labels.interview}</h3>
                        </header>
                        <Section
                            alt={getAlt()}
                            videos={doVideoFilter(interview_videos)}
                            onClick={onSectionClick(interview_videos)}
                            setLogin={setLogin}
                        />
                    </div>
                )}
                {doVideoFilter(kview_videos).length > 0 && (
                    <div className="section-wrap kviews">
                        <header>
                            <h3>{labels.kviews}</h3>
                        </header>
                        <Section
                            alt={getAlt()}
                            videos={doVideoFilter(kview_videos)}
                            onClick={onSectionClick(kview_videos)}
                            setLogin={setLogin}
                        />
                    </div>
                )}
                {doVideoFilter(copyVideosFromFavs()).length > 0 && (
                    <div className="section-wrap top">
                        <header>
                            <h3 class="favs-star">{labels.favorites}</h3>
                        </header>
                        <Section
                            alt={getAlt()}
                            videos={doVideoFilter(copyVideosFromFavs())}
                            onClick={onSectionClick(copyVideosFromFavs())}
                            setLogin={setLogin}
                        />
                    </div>
                ) }
                {/* FROM THE WEB SECTION doVideoFilter(external_videos).length > 0 && (
              <div className="section-wrap external">
                <header>
                  <h3>{labels.external}</h3>
                </header>
                <Section
                  alt={getAlt()}
                  videos={doVideoFilter(external_videos)}
                  onClick={onSectionClick(external_videos)}
                  setLogin={setLogin}
                />
              </div>
            )*/}
                {custom_channels.filter(c => c.bottom).map(channelSection)}
            </div>
            {/*{modalVideo && (*/}
            {/*    <div className="modal video">*/}
            {/*        <a className="close" onClick={() => setModalVideo(not)} />*/}
            {/*        <Video*/}
            {/*            vimeoId={modalVideo.vimeoId}*/}
            {/*            embedId={modalVideo.embedId}*/}
            {/*            thumb={modalVideo.image_url}*/}
            {/*            url={ loggedIn ? modalVideo.mp4 : modalVideo.mp4_clip}*/}
            {/*            muted={false}*/}
            {/*            controls*/}
            {/*            playing*/}
            {/*        />*/}
            {/*        {modalVideo.permalink && (*/}
            {/*            <a*/}
            {/*                href={modalVideo.permalink.replace('54.218.253.163', 'kadist.org')}*/}
            {/*                className="permalink"*/}
            {/*            >*/}
            {/*      About this video*/}
            {/*            </a>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*)}*/}
            {login && (
                <div className="modal">
                    <form action={`https://kadist.org/wp-login.php?redirect_to=${loginStr}`} method="post">
                        <a className='close' onClick={() => setLogin(not)} />
                        <h3>Member Login-In</h3>
                        <div>
                            <label>Username</label>
                            <input type="text" name="log" />
                        </div>
                        <div>
                            <label>Password</label>
                            <input type="password" name="pwd" />
                        </div>
                        <div>
                            <input type="hidden" name="redirect_to" value={loginStr} />
                            <input className="btn" type="submit" value="SUBMIT" />
                        </div>
                        <p>
                            Don't have an account? <a href="https://kadist.org/register/">Register here.</a>
                        </p>
                    </form>
                </div>
            )}
            {!loggedIn && !beenHereBefore && window.firstTimeVisitorMessage && (
                <div className="modal">
                    <a
                        className="close"
                        onClick={() => {
                            setBeenHereBefore(not);
                            setStorage('beenHereBefore', true);
                        }}
                    />
                    <div
                        className="content"
                        dangerouslySetInnerHTML={{ __html: window.firstTimeVisitorMessage }}
                    />
                </div>
            )}

        </div>
    )
}
