/* This is the Root component mainly initializes Redux and React Router. */

import React from 'react';
import { Provider } from 'react-redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { hot, setConfig } from 'react-hot-loader';
import store from './common/store';
import routeConfig from './common/routeConfig';
import history from './common/history';

setConfig({
    logLevel: 'debug',
});

function renderRouteConfigV3(routes, contextPath) {
    // Resolve route config object in React Router v3.
    const children = []; // children component list

    const renderRoute = (item, routeContextPath) => {
        let newContextPath;
        if (/^\//.test(item.path)) {
            newContextPath = item.path;
        } else {
            newContextPath = `${routeContextPath}/${item.path}`;
        }
        newContextPath = newContextPath.replace(/\/+/g, '/');
        if (item.component && item.childRoutes) {
            const childRoutes = renderRouteConfigV3(item.childRoutes, newContextPath);
            children.push(
        <Route
            key={newContextPath}
            render={props => <item.component {...props}>{childRoutes}</item.component>}
            path={newContextPath}
        />
            );
        } else if (item.component) {
            children.push(
        <Route key={newContextPath} component={item.component} path={newContextPath} exact />
            );
        } else if (item.childRoutes) {
            item.childRoutes.forEach(r => renderRoute(r, newContextPath));
        }
    };

    routes.forEach(item => renderRoute(item, contextPath));

    {console.log(JSON.stringify(routes, null, 2));}

    // Use Switch so that only the first matched route is rendered.
    return <BrowserRouter><Switch>{children}</Switch></BrowserRouter>;
}

function Root() {
    const children = renderRouteConfigV3(routeConfig, '/');
    return (
        <Provider store={store}>
            <ConnectedRouter history={history}>{children}</ConnectedRouter>
        </Provider>
    );
}

export default hot(module)(Root);
