import React, { useEffect, useState } from 'react';
import Video from './Video';
import cn from 'classnames';
import { useIsAdmin } from '../common/redux/isAdmin';
import { pinVideo, unpinVideo } from '../../common/utils';
import { YoutubeEmbed } from '../common';
import { IS_LOCAL, LOGGED_IN } from '../../common/utils';
// import PropTypes from 'prop-types';

export default function VideoHero ({ visible, video, isSelected, playing, muted, onClick, params }) {
    const [hovered, setHovered] = useState(false);
    const [pinned, setPinned] = useState(video.pinned);
    const [showDetails, setShowDetails] = useState(false);
    const { isAdmin } = useIsAdmin();

    const duration = new Date(video.extracted_duration * 1000);
    const hours = duration.getUTCHours();
    const minutes = duration.getUTCMinutes() + (hours * 60);
    const seconds = duration.getUTCSeconds();
    let formattedDuration = '';
    if (!isNaN(seconds)) {
        if (minutes > 0 && seconds === 0) {
            formattedDuration = `${(minutes < 10 && hours ? '0' + minutes : minutes)} ${(minutes === 1 ? 'minute' : 'minutes')}`;
        } else if (minutes === 0 && seconds > 0) {
            formattedDuration = `${(seconds < 10 ? '0' + seconds : seconds)} ${(seconds === 1 ? 'second' : 'seconds')}`;
        } else if (minutes === 0 && seconds === 0) {
            formattedDuration = '';
        }
        else {
            formattedDuration = `${(minutes < 10 && hours ? '0' + minutes : minutes)} min ${(seconds < 10 ? '0' + seconds : seconds)} sec`;
        }

    }
    const video_caption = video.work_details && video.work_details.videoCaption ? <p>{video.work_details.videoCaption}</p>: '';
    const runtime_caption = video.work_details && video.work_details.runtimeCaption ? <p>{video.work_details.runtimeCaption}</p>: '';
    const date_caption = video.work_details && video.work_details.dateCaption ? video.work_details.dateCaption : '';
    const country_caption = video.work_details && video.work_details.countryCaption ? video.work_details.countryCaption : '';
    const video_language = video.work_details && video.work_details.videoLanguage ? video.work_details.videoLanguage : '';
    const video_description = video.work_details && video.description ? <p class="video-description">{video.description}</p>: '';
    const video_title = video.work_details && video.work_details.newVideoTitle ? video.work_details.newVideoTitle : video.extracted_video_title;
    const video_thumb = video.work_details && video.work_details.videoThumbnail ? video.work_details.videoThumbnail : video.image_url;
    return (
        <div
            className={cn('home-video-hero', { selected: isSelected, hover: hovered })}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <header>
                <h2>
                    <a onClick={onClick}>{video_title}</a>
                    {hovered && isAdmin && <a onClick={() => {
                        if (pinned) {
                            unpinVideo(video.id);
                            setPinned(false);
                        } else {
                            pinVideo(video.id);
                            setPinned(true);
                        }
                    }} className={cn('pin', { pinned })}>{pinned ? 'unpin' : 'pin'}</a>}
                </h2>
                <p>
                    {video.extracted_video_artist ? `${video.extracted_video_artist}` : ''}
                    {video.extracted_year ? `,  ${video.extracted_year}` : ''}
                    {formattedDuration ? `,  ${formattedDuration}` : ''}
                </p>
                <div className="subcaption">
                    <p>{video_language ? `Language: ${video_language}, ` : ''}</p>
                    <p>{country_caption ? `Country: ${country_caption}, ` : ''}</p>
                    <p>{date_caption ? `Created: ${date_caption}` : ''}</p>
                </div>
                {video_caption}
                <div className={`description-trunc  ${showDetails ? 'showing' : ''}`}>
                    { video_description }
                    {/*{date_caption}*/}
                    {/*{runtime_caption}*/}
                    {/*{country_caption}*/}
                </div>
                { video_description && <a class="read-more" onClick={() => setShowDetails(!showDetails)}>{showDetails ? 'show less' : 'read more'}</a> }
                <div className="detail-reveal">

                    {/*<div className="description-reveal">*/}
                    {/*  <a onClick={()=> setShowDetails(!showDetails)}>Explore this work on Kadist.org</a>*/}
                    {/*</div>*/}
                    <br></br>
                </div>
            </header>
            {/*<div className='video-duration' style={{*/}
            {/*  position: 'absolute',*/}
            {/*  right: '80px',*/}
            {/*  top: 0,*/}
            {/*  font: 'normal 24px/27px "UnicaRegular",Helvetica,Arial,sans-serif',*/}
            {/*  zIndex: 1000*/}
            {/*}}>{<p>{formattedDuration}</p>}</div>*/}
            <Video
                embedId={video.embedId}
                vimeoId={video.vimeoId}
                url={LOGGED_IN ? video.mp4 : (video.mp4_clip || video.mp4)}
                playing={isSelected && playing}
                muted={isSelected ? muted : true}
                thumb={video_thumb|| ''}
                onClick={onClick}
                playsinline
            />
        </div>
    );
}

VideoHero.propTypes = {};
VideoHero.defaultProps = {};
