import React from 'react';
import 'whatwg-fetch';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function App ({ children }) {
    return (
        <div className='home-app'>
            <div className='page-container'>{children}</div>
        </div>
    );
}
