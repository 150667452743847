import { WelcomePage, PlainCarousel } from './';

export default {
    path: '/',
    childRoutes: [
        { path: 'welcome-page', component: WelcomePage, isIndex: true },
        { path: 'plain', component: PlainCarousel },
        { path: 'kadist-tv/', component: WelcomePage, isIndex: true},
        { path: 'kadist-tv/:id', component: WelcomePage, isIndex: true},
    ],
};
