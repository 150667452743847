import { append, without } from 'ramda';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { pinVideo } from '../../../common/utils';
import {
    COMMON_SET_FAVS_BEGIN,
    COMMON_SET_FAVS_SUCCESS,
    COMMON_SET_FAVS_FAILURE,
    COMMON_SET_FAVS_DISMISS_ERROR
} from './constants';

export function setFavs(vId) {
    return (dispatch, getState) => { // optionally you can have getState as the second argument
        dispatch({
            type: COMMON_SET_FAVS_BEGIN,
        });

        const doFetch = (query) => {
            const url = /localhost/.test(window.location.href) ? 'http://localhost/' : '/';
            return fetch(`${url}?${query}`,  {
                credentials: 'include', // include, same-origin, *omit
            })
        .then(response => response.json());
        };
        if (typeof vId === 'string') {
            let current = getState().common.favs;
            const data = current.includes(vId) ? without([vId], current) : append(vId, current);
            dispatch({
                type: COMMON_SET_FAVS_SUCCESS,
                data,
            });
            return doFetch(data.map(id => `favs[]=${id}`).join('&')).then(favs => {
                dispatch({
                    type: COMMON_SET_FAVS_SUCCESS,
                    data: favs,
                });
            });
        }
        return doFetch('favs=hi')
      .then(favs => {
          dispatch({
              type: COMMON_SET_FAVS_SUCCESS,
              data: favs,
          });
      });
    };
}

export function dismissSetFavsError() {
    return {
        type: COMMON_SET_FAVS_DISMISS_ERROR,
    };
}

export function useSetFavs() {
    const dispatch = useDispatch();

    const { setFavsPending, setFavsError, favs } = useSelector(
    state => ({
        setFavsPending: state.common.setFavsPending,
        setFavsError: state.common.setFavsError,
        favs: state.common.favs,
    }),
    shallowEqual
    );

    const boundAction = useCallback((...args) => {
        return dispatch(setFavs(...args));
    }, [dispatch]);

    const boundDismissError = useCallback(() => {
        return dispatch(dismissSetFavsError());
    }, [dispatch]);

    return {
        setFavs: boundAction,
        setFavsPending,
        setFavsError,
        favs,
        dismissSetFavsError: boundDismissError,
    };
}

export function reducer(state, action) {
    switch (action.type) {
        case COMMON_SET_FAVS_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                setFavsPending: true,
                setFavsError: null,
            };

        case COMMON_SET_FAVS_SUCCESS:
            // The request is success
            return {
                ...state,
                favs: action.data,
                setFavsPending: false,
                setFavsError: null,
            };

        case COMMON_SET_FAVS_FAILURE:
            // The request is failed
            return {
                ...state,
                setFavsPending: false,
                setFavsError: action.data.error,
            };

        case COMMON_SET_FAVS_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                setFavsError: null,
            };

        default:
            return state;
    }
}
