import React, { useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSetFavs } from '../common/redux/hooks';
import {
    Video
} from './';
import { isMobileWidth, LOGGED_IN } from '../../common/utils';
import SectionVideoWrap from './SectionVideoWrap';
import { nth, flatten, last, map, pipe, splitEvery } from 'ramda';
import { useEffect } from 'react';
const Section = memo(({ videos = [], onClick, setLogin, alt }) => {
    const [activeVideoId, setActiveVideoId] = useState(false);
    const [videoPlaying, _setVideoPlaying] = useState(false);
    const { setFavs, favs } = useSetFavs();
    const timeoutRef = useRef({});
    const videoRef = useRef(null);
    const wrapRef = useRef(null);

    const setVideoPlaying = (videoPlaying) => {
        videoRef.current = videoPlaying;
        _setVideoPlaying(videoPlaying);
    };
    const setVideoId = (id) => {
        setActiveVideoId(id);
        if (timeoutRef.current.id === id) {
            return;
        }
        clearTimeout(timeoutRef.current.tout);
        if (id === false) {
            timeoutRef.current = {};
            setVideoPlaying(false);
            return;
        }
        timeoutRef.current = {
            id,
            tout: setTimeout(() => {
                // setVideoPlaying(id) // commented out to prevent video from playing when hovering on the video
            }, 3000),
        };
    };
    const svw = v => {
        const playing = videoPlaying === v.id;
        return <SectionVideoWrap
            key={v.id}
            v={v}
            playing={playing}
            onMouseEnter={() => setVideoId(v.id)}
            onMouseLeave={() => setVideoId(false)}
            onClick={() => {
                if (onClick) {
                    onClick(v);
                }
            }}
            onFav={() => {
                if (LOGGED_IN) {
                    setFavs(v.id);
                } else if (setLogin) {
                    setLogin(true);
                }
            }}
        />;
    };
    return (
        <div className='home-section-wrap'>
            {wrapRef.current && wrapRef.current.scrollLeft > 0 && <a className='left' onClick={() => {
                wrapRef.current.scrollTo({
                    left: wrapRef.current.scrollLeft - wrapRef.current.clientWidth,
                    behavior: 'smooth',
                });
            }} />}
            <div ref={wrapRef} className='home-section'>
                <div className='videos-wrap'>
                    {!isMobileWidth() ? map(svw, videos) : pipe(
            splitEvery(5),
            map((arr, i) => {
                const four = alt ? arr.slice(1, 5) : arr.slice(0, 4);
                const one = alt ? nth(0, arr) : last(arr);
                const ret = [<span key={i} className='group'>
                    {map(svw, four)}
                </span>];
                if (one) {
                alt ? ret.unshift(svw(one)) : ret.push(svw(one));
                }
                return ret;
            }),
            flatten
                    )(videos)}
                </div>
            </div>
            {wrapRef.current && wrapRef.current.scrollLeft < wrapRef.current.scrollWidth - wrapRef.current.clientWidth && <a className='right' onClick={() => {
                wrapRef.current.scrollTo({
                    left: wrapRef.current.scrollLeft + wrapRef.current.clientWidth,
                    behavior: 'smooth',
                });
            }} />}
        </div>
    );
});

Section.propTypes = {
    fetchVideos: PropTypes.func,
};
Section.defaultProps = {};

export default Section;
