import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    COMMON_FETCH_VIDEOS_BEGIN,
    COMMON_FETCH_VIDEOS_SUCCESS,
    COMMON_FETCH_VIDEOS_FAILURE,
    COMMON_FETCH_VIDEOS_DISMISS_ERROR
} from './constants';
import { statsList, fetchAll, fetchTrending, pinnedList, shuffle } from '../../../common/utils';
import { pipe, find, map, prepend, prop, propEq, uniqBy, uniq, flatten } from 'ramda';

const CUSTOM_CHANNEL = 'custom-channel';

export function fetchVideos (args = {}) {
    return (dispatch) => { // optionally you can have getState as the second argument
        dispatch({
            type: COMMON_FETCH_VIDEOS_BEGIN,
        });
        // all videos
        let addPins; let stats;
        return statsList()
      .then(sl => {
          stats = sl;
          return pinnedList();
      })
      .then(pins => {
          addPins = map(video => {
              video.pinned = pins.includes(video.id);
              video.popularity = stats[video.id] || 0;
              return video;
          });
          return fetchAll();
      })
      .then(data => {
          const allVids = pipe(flatten, uniqBy(prop('id')))(['kvl_videos', 'interview_videos', 'external_videos', 'kview_videos'].map(key => {
              const vids = addPins(uniqBy(prop('id'), data.videos[key]));
              dispatch({
                  type: COMMON_FETCH_VIDEOS_SUCCESS,
                  key,
                  data: vids,
              });
              return vids;
          }));
          if (window.ktvChannels) {
              dispatch({
                  type: CUSTOM_CHANNEL,
                  data: window.ktvChannels.map(channel => {
                      const getExternalVideo = vId => {
                          if (typeof vId === 'string') {
                              return find(propEq('id', vId), allVids);
                          }
                          if (typeof vId === 'object') {
                              if (vId.youtube) {
                                  return {
                                      extracted_video_title: vId.title,
                                      extracted_video_artist: vId.artist,
                                      image_url: `https://img.youtube.com/vi/${vId.youtube}/0.jpg`,
                                      embedId: vId.youtube,
                                      id: channel.title.replace(/^[A-Za-b]/g, '') + vId.youtube,
                                  };
                              } else if (vId.vimeo) {
                                  // get thumbnail info
                                  fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${vId.vimeo}`)
                      .then(res => res.json())
                      .then(info => {
                          dispatch({
                              type: 'update-custom-channel',
                              data: {
                                  channelTitle: channel.title,
                                  vimeoId: vId.vimeo,
                                  image_url: info.thumbnail_url,
                              },
                          });
                      });
                                  return {
                                      extracted_video_title: vId.title,
                                      extracted_video_artist: vId.artist,
                                      image_url: `http://i.vimeocdn.com/video/${vId.vimeo}_640.webp`,
                                      vimeoId: vId.vimeo,
                                      id: channel.title.replace(/^[A-Za-b]/g, '') + vId.vimeo,
                                  };
                              }
                          }
                      };
                      return {
                          title: channel.title,
                          bottom: channel.bottom,
                          description: channel.description,
                          videos: channel.videos.map(getExternalVideo).filter(Boolean),
                          start: channel.start || null,
                          end: channel.end || null,
                          endCTA: channel.endCTA || null,
                      };
                  }),
              });
          }
          // suggested
          return fetchTrending();
      })
      .then(data => {
          const videos = window.ktvLive ? prepend(window.ktvLive, data.videos) : data.videos;
          dispatch({
              type: COMMON_FETCH_VIDEOS_SUCCESS,
              key: 'trending',
              data: addPins(uniqBy(prop('id'), videos)).sort((a, b) => {
                  if (a.pinned && !b.pinned) {
                      return -1;
                  }
                  if (!a.pinned && b.pinned) {
                      return 1;
                  }
                  return 0;
              }),
          });
      })
      .catch(err => {
          dispatch({
              type: COMMON_FETCH_VIDEOS_FAILURE,
              data: { error: err },
          });
      });
    };
}

export function dismissFetchVideosError () {
    return {
        type: COMMON_FETCH_VIDEOS_DISMISS_ERROR,
    };
}

export function useFetchVideos () {
    const dispatch = useDispatch();

    const st8Obj = useSelector(
    state => {
        const st8 = {
            fetchVideosPending: state.common.fetchVideosPending,
            fetchVideosError: state.common.fetchVideosError,
            trending: state.common.trending,
            kvl_videos: state.common.kvl_videos,
            external_videos: state.common.external_videos,
            interview_videos: state.common.interview_videos,
            kview_videos: state.common.kview_videos,
            custom_channels: state.common.custom_channels,
            regions: state.common.regions,
            durations: state.common.durations,
            isPaging: state.common.isPaging
        };
        return st8;
    },
    shallowEqual
    );

    const boundAction = useCallback((...args) => {
        return dispatch(fetchVideos(...args));
    }, [dispatch]);

    const boundDismissError = useCallback(() => {
        return dispatch(dismissFetchVideosError());
    }, [dispatch]);

    const setIsPaging = useCallback((isPaging) => {
        st8Obj.isPaging = isPaging;
    });

    return Object.assign({
        fetchVideos: boundAction,
        dismissFetchVideosError: boundDismissError,
        setIsPaging: setIsPaging,
    }, st8Obj);
}

export function reducer (state, action) {
    switch (action.type) {
        case COMMON_FETCH_VIDEOS_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                fetchVideosPending: true,
                fetchVideosError: null,
            };
        case 'update-custom-channel':
            return {
                ...state,
                custom_channels: state.custom_channels.map(channel => {
                    if (channel.title === action.data.channelTitle) {
                        return {
                            ...channel,
                            videos: channel.videos.map(video => {
                                if (video.vimeoId === action.data.vimeoId) {
                                    video.image_url = action.data.image_url;
                                }
                                return video;
                            }),
                        };
                    }
                    return channel;
                }),
            };

        case CUSTOM_CHANNEL:
            return {
                ...state,
                custom_channels: state.custom_channels.concat(action.data),
            };

        case COMMON_FETCH_VIDEOS_SUCCESS:
            // The request is success
            const st8 = {
                ...state,
                fetchVideosPending: false,
                fetchVideosError: null,
            };
            st8[action.key] = action.key === 'trending' ? st8[action.key].concat(action.data) : st8[action.key].concat(shuffle(action.data, (new Date()).getDay()));
            st8.regions = uniq(st8.regions.concat(action.data.map(prop('region'))));
            return st8;

        case COMMON_FETCH_VIDEOS_FAILURE:
            // The request is failed
            return {
                ...state,
                fetchVideosPending: false,
                fetchVideosError: action.data.error,
            };

        case COMMON_FETCH_VIDEOS_DISMISS_ERROR:
            // Dismiss the request failure error
            return {
                ...state,
                fetchVideosError: null,
            };

        default:
            return state;
    }
}
